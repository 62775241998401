<template>
  <b-row class="mycard bg-white py-2 px-4 m-2 text-center">
    <b-col
      cols="4"
      class="d-flex flex-row justify-content-center align-items-center"
    >
      <p class="m-0">{{ data1 }}</p>
    </b-col>
    <b-col
      cols="4"
      class="d-flex flex-row justify-content-center align-items-center"
    >
      <p class="m-0">{{ data2 }}</p>
    </b-col>
    <b-col
      cols="4"
      class="d-flex flex-row justify-content-center align-items-center"
    >
      <p class="m-0">$ {{ data3 }}</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ["data1", "data2", "data3"],
};
</script>

<style lang="scss" scoped></style>
