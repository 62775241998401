<template>
  <div>
    <div class="mobileNav">Billing</div>
    <b-container>
      <div class="px-3 my-4">
        <b-row class="my-2">
          <!-- Amount Information  -->
          <b-col sm="12" md="6" class="px-4">
            <h5 class="mb-4">Amount Due</h5>
            <div class="bg-white p-4 border-radius-up position-relative">
              <div
                class="position-absolute"
                style="top: -10px; right: -10px; z-index: 3"
              >
                <el-popover
                  placement="right"
                  title="Help"
                  width="300"
                  trigger="hover"
                >
                  <QuestionMark slot="reference" />
                  <div class="help">
                    <p>
                      Please pay the outstanding balance before
                      <span>
                        {{ payBeforeDate }}
                      </span>
                      to avoid a late payment fee of 5% on your bill.
                    </p>
                  </div>
                </el-popover>
              </div>
              <h5 class="text-diy mb-3">
                <span>
                  {{ invoiceDate }}
                </span>
              </h5>
              <b-row class="text-center">
                <b-col sm="12" md="6" class="p-2">
                  <h1 class="text-diy">$ {{ balance }}</h1>
                </b-col>
                <b-col sm="12" md="6" class="p-2">
                  <div class="yourPlanPrice">
                    <p class="m-0">
                      Your Plan Price:
                      <span class="money">{{
                        currentPlan.split("/")[0] + " / m"
                      }}</span>
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div
              class="p-4 border-radius-down text-diy"
              style="background-color: #d6f2f5"
            >
              <p class="m-0 w-500">
                Pay before:
                <span>
                  {{ payBeforeDate }}
                </span>
              </p>
            </div>
            <div
              class="d-flex flex-row justify-content-between align-items-center my-5"
            >
              <div class="mx-2" @click="goToPaymentPage()">
                <button class="mybtn">PAY</button>
              </div>
              <div class="mx-2" @click="goToTheCurrentMonthPdfPage()">
                <button class="mybtn-blue">PDF Invoice</button>
              </div>
            </div>
          </b-col>
          <!-- Recent Account Transactions  -->
          <b-col cols="12" md="6" class="px-4">
            <h5 class="mb-4">Recent Account Transactions</h5>
            <AccountTransactioBar
              class="text-info"
              data1="Date"
              data2="Type"
              data3="Amount"
            />
            <div>
              <div
                v-for="(data, index) in last30DayAccountTransactions"
                :key="index"
              >
                <AccountTransactioBar
                  :data1="data.Timestamp.split('T')[0]"
                  :data2="processNameFormat(data.ItemName)"
                  :data3="data.ItemAmount"
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <hr />
        <!-- previous invoices - year picker  -->
        <b-row>
          <b-col md="6" sm="12">
            <div
              class="d-flex flex-row justify-content-between align-items-center my-2 p-3"
            >
              <h5 class="m-0">Previous Invoices</h5>
              <el-select v-model="selectedYear" placeholder="Please Select">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </b-col>
        </b-row>
        <!-- previous invoices section  -->
        <b-row>
          <b-col sm="12" md="6" class="px-4 my-2">
            <div
              v-for="{ Month, TotalAmount } in selectedYearInovoices"
              :key="Month"
            >
              <b-row
                class="text-center bg-white py-3 m-3 mycard invoice justify-content-center align-items-center animate__animated animate__fadeIn"
                v-show="
                  !(
                    monthsAbbreviation[curMonth] === Month &&
                    !isEmailNotificationSent
                  )
                "
              >
                <b-col>
                  <p>{{ Month }}</p>
                </b-col>
                <b-col>
                  <p>${{ TotalAmount }}</p>
                </b-col>
                <b-col>
                  <div @click="handleShowDetailEvent(Month)">
                    <p class="buttonStyle">Details</p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="px-4 my-2"
            v-show="isShowMonthlyDetail"
          >
            <div class="bg-white p-4 mycard m-1">
              <b-row>
                <b-col md="6" sm="12">
                  <div class="monthDetailBackStyle d-flex ">
                    <i
                      class="fas fa-chevron-left"
                      @click="isShowMonthlyDetail = false"
                    ></i>
                    <p class="m-0 d-inline">
                      {{ this.months[this.selectedMonthIndex] }}
                    </p>
                  </div>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                  class="d-flex justify-content-end align-items-center"
                >
                  <div>
                    <b-button
                      class="bg-blue"
                      style="border: 0px; outline: none !important"
                      @click="goToThePdfPage()"
                    >
                      <!-- View & <br class="d-block d-sm-none" />Download -->
                      View Full PDF Invoice
                    </b-button>
                  </div>
                </b-col>
              </b-row>

              <p class="text-uppercase text-center m-4">
                {{ this.months[this.selectedMonthIndex] }} Invoice Details
              </p>
              <div>
                <div
                  v-for="{
                    ItemName,
                    ItemAmount,
                  } in selectedMonthInvoiceBreakDown"
                  :key="ItemName"
                  class="d-flex flex-row justify-content-between"
                >
                  <p>{{ processNameFormat(ItemName) }}</p>
                  <p>${{ (Math.round(ItemAmount * 100) / 100).toFixed(2) }}</p>
                </div>
              </div>
              <hr />
              <div class="d-flex flex-row justify-content-between">
                <p>TOTAL</p>
                <p>${{ selectedMonthInvoiceTotal }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import QuestionMark from "../../components/QuestionMark";
import AccountTransactioBar from "./utils/AccountTransactionBar";
// import router from "../../router";
export default {
  name: "Billing",
  components: {
    QuestionMark,
    AccountTransactioBar,
  },
  data() {
    return {
      isMobileOrTablet: false,
      // client info
      balance: "",
      currentPlan: "",
      options: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      monthsAbbreviation: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      htmlTitle: "PhoneBox Invoice",
      actYear: "",
      actMonth: "",
      curMonth: "",
      curYear: "",
      curDayOfMonth: "",
      isShowMonthlyDetail: false,
      selectedYear: "",
      selectedMonth: "",
      selectedMonthInt: null,
      selectedMonthIndex: 0,
      last30DayAccountTransactions: [],
      selectedYearInovoices: [],
      selectedMonthInvoice: {},
      selectedMonthInvoiceBreakDown: [],
      selectedMonthInvoiceTotal: 0,
      isEmailNotificationSent: false,
    };
  },
  computed: {
    payBeforeDate() {
      if (this.curDayOfMonth >= 16) {
        return `${this.months[this.curMonth]} 25, ${this.curYear}`;
      } else  if (this.months[this.curMonth - 1]) {
        return `${this.months[this.curMonth - 1]} 25, ${this.curYear}`;
      } else {
        return `${this.months[11]} 25, ${this.curYear - 1}`;
      }
    },
    invoiceDate() {
      if (this.curDayOfMonth >= 16) {
        return `${this.months[this.curMonth]} ${this.curYear}`;
      } else  if (this.months[this.curMonth - 1]) {
        return `${this.months[this.curMonth - 1]} ${this.curYear}`;
      } else {
        return `${this.months[11]} ${this.curYear - 1}`;
      }
    },
  },
  methods: {
    goToPaymentPage() {
      this.$store.state.login.cnumAcctOwnerSaved ===
        this.$store.state.login.cnum &&
      this.$store.state.mutilLinesAccounts.length > 1
        ? this.$router.push("group-payment")
        : this.$router.push("payment");
    },
    getClientInfo() {
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          // console.log(data);
          this.currentPlan = data.CurrentPlan;
          this.balance = data.CurrentBalance;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateYearPicker() {
      this.$axios
        .get("/Account/GetProfile")
        .then((response) => {
          let data = JSON.parse(response.data);
          let actDate = data["ActDate"].split(" ")[0];
          this.actYear = actDate.split("/")[2];
          this.actMonth = actDate.split("/")[0];
          //inovice becomes available on the 15th of each month
          //if it's before Januray 15, then first available billing month is December last year
          if (this.curMonth == 0 && this.curDayOfMonth <= 15) {
            console.log("here");
            for (let i = this.curYear - 1; i >= this.actYear; i--) {
              console.log(i);
              this.options.push({
                value: i,
                label: i,
              });
            }
            console.log(this.options);
          } else {
            for (let i = this.curYear; i >= this.actYear; i--) {
              this.options.push({
                value: i,
                label: i,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleShowDetailEvent(month) {
      this.isShowMonthlyDetail = true;
      this.selectedMonth = month;
      this.selectedMonthIndex = this.monthsAbbreviation.indexOf(month);
      //log is a bit complicated here:
      //1. API returns MonthlyInvoiceDetail as an Array of Object from the SelectedYear in Descending Order (December => Januray)
      //2. different cases needs to consider here in order to get the correct index from the Array
      //Case 1: selected Year is Activation Year: => result = arr[arr.length - (m1 + 1) - m2]
      if (this.selectedYear == this.actYear) {
        // this.selectedMonthInvoice = this.selectedYearInovoices[
        //   this.selectedYearInovoices.length -
        //     (this.selectedMonthIndex + 1 - this.actMonth)
        // ];
        this.selectedMonthInt =
          this.selectedYearInovoices.length -
          (this.selectedMonthIndex + 1 - this.actMonth);
      }
      //Case 2: selected Year is not Activation Year => result = arr[arr.length - (m1 + 1)]
      else {
        // this.selectedMonthInvoice = this.selectedYearInovoices[
        //   this.selectedYearInovoices.length - (this.selectedMonthIndex + 1)
        // ];
        this.selectedMonthInt =
          this.selectedYearInovoices.length - (this.selectedMonthIndex + 1);
      }
      // console.log(this.selectedMonthInt);
      this.selectedMonthInvoice = this.selectedYearInovoices[
        this.selectedMonthInt
      ];
      this.selectedMonthInvoiceBreakDown = this.selectedMonthInvoice[
        "InvoiceDetail"
      ];
      // console.log(this.selectedMonthInvoiceBreakDown);
      this.selectedMonthInvoiceTotal = this.selectedMonthInvoice["TotalAmount"];
      this.selectedMonthInvoiceTotal = (
        Math.round(this.selectedMonthInvoiceTotal * 100) / 100
      ).toFixed(2);
      // move to bottom for mobile users
      if (this.isMobileOrTablet) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    },
    getRecentAccountTransaction() {
      this.$axios
        .get("/Account/RecentAccountTransaction", {
          params: {
            curDate: new Date().toLocaleDateString("en-CA"),
          },
        })
        .then((response) => {
          this.last30DayAccountTransactions = JSON.parse(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSelectedYearInvoices() {
      this.$axios
        .get("/Account/InvoiceInfo", {
          params: {
            year: this.selectedYear,
          },
        })
        .then((response) => {
          this.selectedYearInovoices = JSON.parse(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToThePdfPage() {
      // passing data to the pdf page
      // selected year - this.selectedYear
      // this.selectedMonthIndex + 1 -> regular month 1: Jan 2: Feb ... 12: Dec
      this.$store.commit("setPostpaidPdfInvoiceData", {
        key: "year",
        value: this.selectedYear,
      });
      this.$store.commit("setPostpaidPdfInvoiceData", {
        key: "month",
        value: this.selectedMonthIndex + 1,
      });

      this.$router.push({ name: "PostpaidInvoicePDF" });
    },
    goToTheCurrentMonthPdfPage() {
      this.$store.commit("setPostpaidPdfInvoiceData", {
        key: "year",
        value: this.curYear,
      });
      let monthTemp = this.isEmailNotificationSent
        ? this.curMonth + 1
        : this.curMonth;
      this.$store.commit("setPostpaidPdfInvoiceData", {
        key: "month",
        value: monthTemp,
      });
      this.$router.push({ name: "PostpaidInvoicePDF" });
    },
    processNameFormat(value) {
      switch (value) {
        case "EMPOYEE BENEFIT":
          return "FAMILY DISCOUNT";
        default:
          return value;
      }
    },
    getIsEmailNotificationSent() {
      this.$axios
        .get("/Account/IsNewsLetterSentCompleted", {
          params: {
            year: this.curYear,
            month: this.curMonth + 1,
          },
        })
        .then((response) => {
          this.isEmailNotificationSent = JSON.parse(response.data);
          // console.log(this.isEmailNotificationSent);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    selectedYear() {
      this.isShowMonthlyDetail = false;
      this.getSelectedYearInvoices();
    },
  },
  mounted() {
    this.isMobileOrTablet = window.innerWidth <= 1024;
    this.$store.commit("setCurrentNav", "Billing");
    this.curMonth = new Date().getMonth();
    this.curYear = new Date().getFullYear();
    this.curDayOfMonth = new Date().getDate();
    //inovice becomes available on the 15th of each month
    //if it's before Januray 15, then first available billing month is December last year
    this.selectedYear =
      this.curMonth == 0 && this.curDayOfMonth <= 15
        ? this.curYear - 1
        : this.curYear;
    this.getClientInfo();
    this.updateYearPicker();
    this.getRecentAccountTransaction();
    this.getSelectedYearInvoices();
    this.getIsEmailNotificationSent();
  },
};
</script>

<style lang="scss" scoped>
.billing {
  padding-top: 20px;
}

.yourPlanPrice {
  p {
    font-weight: 300;
    color: #9a9a9a;
    border: 1px solid #9a9a9a;
    border-radius: 10px;
    padding: 15px;
    display: inline-block;
  }
  .money {
    color: #9a9a9a;
    font-weight: 500;
    white-space: nowrap;
  }
}

.invoice {
  p {
    font-size: 18px;
    font-weight: 400;
    color: #62cfd9;
    margin: 0;
  }
}

.buttonStyle:hover {
  cursor: pointer;
}

.monthDetailBackStyle {
  margin-bottom: 25px;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  i {
    color: #36c2cf;
    margin-right: 10px;
    font-size: 18px;
  }
  p {
    font-size: 18px;
    color: #36c2cf;
    font-weight: 500;
  }
}

@media (max-width: 768px) {
  .billing {
    padding-top: 0px;
  }
}
</style>
