<template>
  <div class="main">
    <i class="far fa-question-circle"></i>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

.main {
  i {
  font-size: 25px;
  font-weight: 700;
  }
}

.main:hover {
  cursor: pointer;
}

</style>